<template>
  <div>
    <div v-if="filter" style="display: flex;justify-content: space-between;align-items: flex-end;">
      <div v-if="cashingRecord" style="font-size: 20px;color: #333333;font-weight: 600">提现记录</div>
      <div v-else>
        <div class="header-title">
          <p>累计收益{{ (allMoney || 0).toFixed(2) }}元</p>
          <div class="foot">
            <p>已入账收益{{ (doneMoney || 0).toFixed(2) }}元</p>
            <p>待确认收益{{ (readyMoney || 0).toFixed(2) }}元</p>
          </div>
        </div>
      </div>
      <el-date-picker
          v-model="incomeListRequest.time"
          @change="getIncomeList"
          type="month"
          format="yyyy-MM"
          value-format="yyyy-MM"
          placeholder="选择月">
      </el-date-picker>
    </div>

    <div v-if="cashOutList" style="color: #333333">
      <div style="display: flex;">
        <div v-for="item in billTypeDictionary" :key="item.id" @click="handleCashOutListTypeClick(item.label)"
             style="width: 88px;height: 16px;padding: 12px 16px;margin-right: 16px;cursor: pointer;border-radius: 4px"
             :style="{'background-color': item.label === cashOutListType ? 'rgba(255,0,77,0.10)' : '#f9f9f9'}">
          <div style="font-size: 12px;" :style="{color: item.label === cashOutListType ? '#FF004D' : '#333333'}">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div v-show="total > 0" style="font-size: 24px;font-weight: bold;padding-bottom: 4px;margin-top: 40px">
        您有<span>{{ total }}</span>笔可提现账单，总额<span style="color: #FF477E;">{{ summaryMoney }}</span>元
      </div>
    </div>

    <el-table v-if="isShowTable" :data="incomeList"  :header-cell-style="headerStyle"
              style="width: 100%; margin-top: 20px">
      <el-table-column width="40px"></el-table-column>
      <el-table-column min-width="100" v-if="!cashingRecord" label="账单名称" prop="title"></el-table-column>
      <el-table-column min-width="50" v-if="billTypeDictionary.length > 0" label="账单类型" prop="type" :formatter="getValueByLabel">
        <el-dropdown v-if="filter" trigger="click" slot="header" @command="handleTypeCommand">
          <span class="el-dropdown-link">
            账单类型<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">全部</el-dropdown-item>
            <el-dropdown-item v-for="item in billTypeDictionary" :key="item.id" :command="item.label">
              {{ item.value }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-table-column>
      <el-table-column min-width="100" v-if="cashOutList || cashingRecord" label="时间" prop="actualTime" v-slot="{row}">
        {{ $timestampToString(row.actualTime || row.cashOutDoneTime) }}
      </el-table-column>
      <el-table-column min-width="100" label="付款公司" prop="paymentCompany">
        <el-dropdown v-if="paymentCompanyList.length > 0" trigger="click" slot="header" @command="handlePaymentCommand">
          <span class="el-dropdown-link">
            付款公司<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">全部</el-dropdown-item>
            <el-dropdown-item v-for="item in paymentCompanyList" :key="item" :command="item">
              {{ item }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-table-column>
      <el-table-column min-width="70" label="金额(元)" prop="totalMoney" v-slot="{row}">
        {{ (row.totalMoney >= 0 ? row.totalMoney : row.cashOutMoney).toFixed(2) }}
        <el-link v-if="cashOutList" type="primary" @click="$router.push('/bill/'+ row.id)">详情</el-link>
      </el-table-column>
      <el-table-column min-width="50" v-if="cashingRecord" label="账单进度">
        <el-dropdown v-if="filter" trigger="click" slot="header" @command="handleStatusCommand">
          <span class="el-dropdown-link">
            账单进度<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="''">全部</el-dropdown-item>
            <el-dropdown-item :command="0">提现中</el-dropdown-item>
            <el-dropdown-item :command="1">提现成功</el-dropdown-item>
            <el-dropdown-item :command="2">提现失败</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div slot-scope="{ row }">
          <span>
            <span v-if="row.status === 0">提现中 </span>
            <span v-else-if="row.status === 1">提现成功 </span>
            <span v-else-if="row.status === 2">
              提现失败
              <el-tooltip class="item" effect="dark" :content="row.desp" placement="bottom">
                <el-image :src="tips" style="width: 14px;height: 14px"></el-image>
              </el-tooltip>
              &nbsp;
            </span>
            <el-link type="primary" @click="$router.push('/cashing/' + row.id)">详情</el-link>
          </span>
        </div>
      </el-table-column>
      <el-table-column min-width="50" v-else :label="cashOutList ? '操作' : '账单进度'">
        <el-dropdown v-if="filter" trigger="click" slot="header" @command="handleStatusCommand">
          <span class="el-dropdown-link">
            账单进度<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="''">全部</el-dropdown-item>
            <el-dropdown-item :command="0">待确认</el-dropdown-item>
            <el-dropdown-item :command="1">已确认</el-dropdown-item>
            <el-dropdown-item :command="2">提现中</el-dropdown-item>
            <el-dropdown-item :command="3">已提现</el-dropdown-item>
            <el-dropdown-item :command="4">已撤销</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div slot-scope="{ row }">
          <span v-if="row.status === 0">
            <span style="display:inline-block;width:50px;">待确认</span>
            <el-link
                style="color: #ff004d"
                @click="$router.push('/confirm')">去确认
            </el-link>
          </span>
          <span v-if="row.status === 1">
            <span style="display:inline-block;width:50px;" v-show="!cashOutList">已确认</span>
            <el-link
                v-show="row.totalMoney > 0"
                style="color: #ff004d"
                @click="toConfirm(row)">
              去提现
            </el-link>
          </span>
          <span v-if="row.status === 2">
            <span style="display:inline-block;width:50px;">提现中</span>
              <el-link style="color: #ff004d" @click="$router.push('/bill/'+ row.id)">
                详情
              </el-link>
          </span>
          <span v-if="row.status === 3">
            <span style="display:inline-block;width:50px;">已提现</span>
            <el-link style="color: #ff004d" @click="$router.push('/bill/'+row.id)">详情</el-link>
          </span>
          <span v-if="row.status === 4">
            <span style="display:inline-block;width:50px;">已撤销</span>
          </span>
        </div>
      </el-table-column>
    </el-table>

    <div v-show="!notShowPagination && total > 0" style="text-align: center;width: 100%;margin-top: 30px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="getIncomeList"
          :current-page.sync="incomeListRequest.pageNum"
          :page-size.sync="incomeListRequest.pageSize"
          layout="prev, pager, next, total, jumper"
          :total.sync="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
/**
 * 一级账单表格
 */
import {cashOutRecordV2, incomeList, myBankMsg, myPaymentCompany, readyWithdrawList} from "@/api/income"
import {dictInfo} from "@/api/dictionary"
import {getValueByLabel} from "@/utils/utils"
import tips from "@/assets/tips-img.png"

export default {
  name: "BillTable",
  components: {},
  props: {
    notShowPagination: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 10
    },
    filter: {
      type: Boolean,
      default: false
    },
    cashingRecord: {
      type: Boolean,
      default: false
    },
    cashOutList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      incomeListRequest: {
        endTime: undefined,
        name: undefined,
        pageNum: 1,
        pageSize: undefined,
        startTime: undefined,
        status: undefined,
        time: undefined,
        type: undefined
      },
      isShowTable: false,

      total: 0,

      incomeList: [],

      allMoney: 0,
      doneMoney: 0,
      readyMoney: 0,
      summaryMoney: 0,

      billTypeDictionary: [],
      paymentCompanyList: [],

      cashOutListType: '',

      // 表格头部样式
      headerStyle: {
        background: '#f8f8f8',
        color: '#555555',
        fontSize: '14px',
        fontFamily:'PingFang SC-Medium',
       fontWeight: '500',
      },
      tips
    }
  },
  mounted() {
    // console.log(this.cashOutList)
    this.incomeListRequest.pageSize = this.pageSize
    this.getIncomeList()
    this.getMyBankMsg()
    this.getBillTypeDictionary().then(() => {
      if (!this.filter)
        this.isShowTable = true
      if (this.cashOutList && this.billTypeDictionary.length > 0)
        this.cashOutListType = this.billTypeDictionary[0].label
    })
    if (this.filter) {
      this.getCompanyDictionary().then(() => {
        this.isShowTable = true
      })
    }
  },
  methods: {
    toConfirm(row) {
      if (this.cardInfo.status === null) {
        this.$popup({msg: '您还未绑定银行卡，绑定后才能提现~', type: 'warning'})
        return
      } else if (this.cardInfo.status === 0) {
        this.$popup({msg: '银行卡审核中，请等待审核通过后再进行提现操作', type: 'warning'})
        return
      } else if (this.cardInfo.status === 2) {
        this.$popup({msg: '您的银行卡审核失败，重新绑定后即可提现哦~', type: 'error'})
        return
      }
      this.$router.push('/cash-out/' + row.id)
    },
    getIncomeList() {
      if (this.cashingRecord)
        cashOutRecordV2(this.incomeListRequest).then(r => {
          let foo = []
          r.data.list.forEach(e => {
            foo = foo.concat(e.cashOutOrders)
          })
          this.incomeList = foo
          this.total = r.data.total
        });
      else
        (
            this.cashOutList
                ? readyWithdrawList({pageNum: 1, pageSize: 9999, type: this.cashOutListType})
                : incomeList(this.incomeListRequest)
        ).then(r => {
          this.incomeList = r.data.list
          this.total = r.data.total
          this.allMoney = r.data.allMoney
          this.doneMoney = r.data.doneMoney
          this.readyMoney = r.data.readyMoney
          this.summaryMoney = r.data.summaryMoney
        })
    },
    getBillTypeDictionary() {
      return dictInfo({codes: ['10016']}).then(r => {
        this.billTypeDictionary.push(...r.data)
      })
    },
    getCompanyDictionary() {
      return myPaymentCompany({}).then(r => {
        this.paymentCompanyList = r.data
      })
    },
    getMyBankMsg() {
      myBankMsg(this.$store.state.usermsg.webLoginType ? this.$store.state.usermsg.webLoginType ? 0 : 1 : this.$store.state.user.webLoginType ? 0 : 1 ).then(r => {
        if (r.data === null) {
          this.cardInfo = {
            status: null
          }
        } else {
          this.cardInfo = r.data
        }
      })
    },
    getValueByLabel(_, __, cellValue) {
      return getValueByLabel(this.billTypeDictionary, cellValue)
    },
    handleSizeChange() {
      this.incomeListRequest.pageNum = 1;
      this.getIncomeList();
    },
    handleTypeCommand(command) {
      this.incomeListRequest.type = command
      this.getIncomeList()
    },
    handlePaymentCommand(command) {
      this.incomeListRequest.name = command
      this.getIncomeList()
    },
    handleStatusCommand(command) {
      this.incomeListRequest.status = command
      this.getIncomeList()
    },
    handleCashOutListTypeClick(label) {
      this.cashOutListType = label
      this.getIncomeList()
    }
  }
}
</script>

<style scoped lang="scss">
.header-title {
  margin-top: 16px;
  font-size: 20px;
  font-family:PingFang SC-Medium;
  font-weight: 500;
  p {
    margin: 0;
  }

  > p {
    font-size: 20px;
    font-family:PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
    letter-spacing: 0;
  }

  .foot {
    margin-top: 12px;
    display: flex;
    font-size: 16px;

    p {
      font-size: 16px;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;
      letter-spacing: 0;
    }

    p:nth-child(2) {
      margin-left: 40px;
    }
  }
}
.el-dropdown-link, ::v-deep .el-table th.el-table__cell>.cell {
  font-family: "PingFang SC-Medium";
  color: #555555;
  font-weight: 500;
}
</style>
